<template>
  <div class="dashboard-container">
    <b-row>
      <!-- ! First col -------------------------------------------------------------------------------------------->
      <b-col cols="9">
        <!-- * Stat row -->
        <b-row>
          <b-col cols="3">
            <b-card>
              <h6>ログイン中ユーザー</h6>
              <h2 class="font-weight-bolder">
                {{ dataInfo.login_user_count ? formatNumber(dataInfo.login_user_count) : 0 }}
              </h2>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-card>
              <h6>トーク中ルーム</h6>
              <h2 class="font-weight-bolder">
                {{ dataInfo.active_room_count ?  formatNumber(dataInfo.active_room_count) : 0 }}
              </h2>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-card>
              <h6>トーク｜参加ユーザー</h6>
              <h2 class="font-weight-bolder">
                {{ dataInfo.total_speaker ? formatNumber(dataInfo.total_speaker) : 0 }}
              </h2>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-card>
              <h6>トーク｜視聴ユーザー</h6>
              <h2 class="font-weight-bolder">
                {{ dataInfo.total_listener ?  formatNumber(dataInfo.total_listener) : 0 }}
              </h2>
            </b-card>
          </b-col>
        </b-row>

        <!-- * First chart row ----------------------------------------------->
        <b-row>
          <b-col>
            <b-card>
              <h6>本日のユーザー増減数</h6>
              <div class="stat">
                <h2 class="font-weight-bolder mb-0">
                  {{ formatNumber(user_chart.today_user) }}
                </h2>
                <span>（昨日：{{ user_chart.yesterday_user }}）</span>
                <span
                    :class="
                    user_chart.rating < 100
                      ? 'chart-status-down'
                      : 'chart-status-up'
                  "
                >
                  <feather-icon
                      v-if="user_chart.rating > 100"
                      icon="TrendingUpIcon"
                  />
                  <feather-icon v-else icon="TrendingDownIcon" />
                </span>
                <span class="chart-rate">{{ (user_chart.rating && user_chart.rating !== Infinity ) ? user_chart.rating : 0 }}%</span>
              </div>
              <div class="chart">
                <vue-apex-charts
                    ref="userChart"
                    type="area"
                    height="250"
                    :options="userChartContainer.chartOptions"
                    :series="userChartContainer.series"
                />
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- * Second chart row ----------------------------------------------->
        <b-row>
          <b-col>
            <b-card>
              <h6>本日の課金ユーザー増減数</h6>
              <div class="stat">
                <h2 class="font-weight-bolder mb-0">
                  {{ formatNumber(user_billing_chart.today_paid_user)}}
                </h2>
                <span>（昨日：{{ user_billing_chart.yesterday_paid_user}}）</span>
                <span
                    :class="
                    user_billing_chart.rating < 100
                      ? 'chart-status-down'
                      : 'chart-status-up'
                  "
                >
                  <feather-icon
                      v-if="user_billing_chart.rating > 100"
                      icon="TrendingUpIcon"
                  />
                  <feather-icon v-else icon="TrendingDownIcon" />
                </span>
                <span class="chart-rate">{{ ( user_billing_chart.rating && user_billing_chart.rating !== Infinity ) ? user_billing_chart.rating : 0 }}%</span>
              </div>
              <div class="chart">
                <vue-apex-charts
                    ref="userPaymentChart"
                    type="area"
                    height="250"
                    :options="userPaymentChartContainer.chartOptions"
                    :series="userPaymentChartContainer.series"
                />
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <!-- ! Second col -------------------------------------------------------------------------------------------->
      <b-col cols="3">
        <!-- * card 1: Deleted -->

        <!-- * card 2 -->
        <b-row>
          <b-col>
            <b-card class="card-3-status">
              <h6>【新着】お問い合わせ</h6>
              <h2 class="font-weight-bolder">
                {{ listUserByIdSendMsgUnread.length }}
              </h2>
              <div
                  class="user-info-group"
                  v-for="(item, index) in listCountUnreadMsg"
                  :key="index"
              >
                <div class="user-info-2" @click="matchUserVsInquiry(item.user_id[0])">
                  <b-avatar v-if="item.status && item.status !== STATUS_DELETE_USER" size="42px" :src="item.avatar" />
                  <b-avatar v-else/>
                  <div class="user-info-detail">
                    <span class="block user-info-single">
                      <p v-if="item.status && item.status !== STATUS_DELETE_USER">{{ item.username }}</p>
                      <p v-else> 退会済み </p>
                      <p>{{ formatDateTimeToJs(item.last_message_at) }}</p>
                    </span>
                    <span class="block user-message">
                      {{ item.last_message }}
                    </span>
                  </div>
                </div>
                <div class="separate-line"></div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- * card 3 -->
        <b-row>
          <b-col>
            <b-card class="card-3-status">
              <h6>【未返信】お問い合わせ</h6>
              <h2 class="font-weight-bolder">
                {{ listTopicAdminSeenNotRep.length }}
              </h2>
              <div
                  class="user-info-group"
                  v-for="(item, index) in listCountAdminSeenNotrep"
                  :key="index"
              >
                <div class="user-info-2" @click="matchUserVsInquiry(item.user_id[0])">
                  <b-avatar v-if="item.status && item.status !== STATUS_DELETE_USER" size="42px" :src="item.avatar" />
                  <b-avatar v-else/>
                  <div class="user-info-detail">
                    <span class="block user-info-single">
                      <p v-if="item.status && item.status !== STATUS_DELETE_USER">{{ item.username }}</p>
                      <p v-else> 退会済み </p>
                      <p>{{ formatDateTimeToJs(item.last_message_at) }}</p>
                    </span>
                    <span class="block user-message">
                      {{ item.last_message }}
                    </span>
                  </div>
                </div>
                <div class="separate-line"></div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BFormCheckbox,
  BAlert,
  BFormGroup,
  BAvatar,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from "vue-apexcharts";
import { dashboardChartOption, RELOAD_TIME, STATUS } from "@/constant/constant";
import { apiService } from "@/services/api.service";
import store from "@/store";
import { mapState } from 'vuex';
import moment from "moment";
import { STATUS_DELETE_USER } from '@/constant/constant'
import router from '@/router'
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormCheckbox,
    BAlert,
    flatPickr,
    BFormGroup,
    VueApexCharts,
    BAvatar,
  },
  data() {
    return {
      userChartContainer: {
        series: [
          {
            name: "本日",
            data: [],
          },
          {
            name: "昨日",
            data: [],
          },
        ],
        chartOptions: dashboardChartOption,
      },
      userPaymentChartContainer: {
        series: [
          {
            name: "本日",
            data: [],
          },
          {
            name: "昨日",
            data: [],
          },
        ],
        chartOptions: dashboardChartOption,
      },
      dataInfo: {
        login_user_count: "",
        active_room_count: "",
        total_speaker: "",
        total_owner: "",
        unread_inquiry_count: "",
        unread_inquiry_list: [],
        not_reply_inquiry_count: "",
        not_reply_inquiry_list: [],
      },
      user_chart: {
        yesterday_user: "",
        today_user: "",
        user_data: [],
        rating: null,
      },
      user_billing_chart: {
        yesterday_paid_user: "",
        today_paid_user: "",
        user_data: [],
        rating: null,
      },
      reloadInfo: null,
      listIdUnread : null,
      STATUS_DELETE_USER
    };
  },
  mounted() {
    this.onGetInfoDashboard();
    this.onGetChartDashboard();
    this.reloadInfo = setInterval(() => {
      this.onGetInfoDashboard();
    }, RELOAD_TIME);
  },
  beforeDestroy() {
    clearInterval(this.reloadInfo);
  },
  methods: {
    /* <!--@--> (updateDataChart): Handle assign data fetched into chart ------------------------------------------------------------------------- */
    updateDataChart() {
      this.$refs.userChart.updateSeries([
        {
          name: 'ユーザー増数',
          data: this.user_chart.user_data.register,
        },
        {
          name: 'ユーザー減数',
          data: this.user_chart.user_data.withdraw,
        },
      ]);
      this.$refs.userPaymentChart.updateSeries([
        {
          name: '課金ユーザー増数',
          data: this.user_billing_chart.user_data.paid,
        },
        {
          name: '課金ユーザー減数',
          data: this.user_billing_chart.user_data.cancel,
        },
      ]);
    },

    /* <!--!--> Fetch: GET /dashboard (onGetInfoDashboard) ------------------------------------------------------------------------- */
    async onGetInfoDashboard() {
      this.startLoading();
      try {
        const response = await apiService.get("dashboard");
        this.endLoading();
        const status = response.data.code;
        if (status === STATUS.SUCCESS) {
          this.dataInfo = response.data.data;
        } else {
          this.notifyError(response.data.message);
        }
      } catch (error) {
        if (error.message === 'Network Error'){
          this.notifyError('ネットワークエラー')
        }else{
          this.notifyError(error.data.message);
        }
        this.endLoading();
      }finally {
        this.endLoading();
      }
    },

    /* <!--!--> Fetch: GET /dashboard/chart (onGetChartDashboard) ------------------------------------------------------------------------- */
    async onGetChartDashboard() {
      this.startLoading();
      try {
        const response = await apiService.get("dashboard/chart");
        this.endLoading();

        this.user_chart = response.data.data.user_chart;
        if (this.user_chart.yesterday_user === 0){
          this.user_chart.rating = Math.floor(
              (this.user_chart.today_user / 1) * 100
          );
        }else{
          this.user_chart.rating = Math.floor(
              (this.user_chart.today_user /
                  this.user_chart.yesterday_user) *
              100
          );
        }
        this.user_billing_chart = response.data.data.user_billing_chart;
        if (this.user_billing_chart.yesterday_paid_user === 0){
          this.user_billing_chart.rating = Math.floor(
              (this.user_billing_chart.today_paid_user / 1) * 100
          );
        }else {
          this.user_billing_chart.rating = Math.floor(
              (this.user_billing_chart.today_paid_user /
                  this.user_billing_chart.yesterday_paid_user) *
              100
          );
        }
        this.updateDataChart();
      } catch (error) {
        if (error.message === 'Network Error'){
          this.notifyError('ネットワークエラー')
        }else{
          this.notifyError(error.data.message);
        }
      }finally {
        this.endLoading();
      }
    },
    matchUserVsInquiry(user_id){
      store.dispatch('totalCountUnreadMsg/matchUserVsInquiryChat',user_id);
      router.push({ name: 'inquiry-list' })
    },
    formatDateTimeToJs(d){
      let dateTimeFormat = null;
      if (d){
        dateTimeFormat =  moment(d * 1000).format('YYYY/MM/DD HH:mm');
      }
      return dateTimeFormat;
    },
  },
  computed : {
    ...mapState({
      listUserByIdSendMsgUnread : state => state.totalCountUnreadMsg.listUserByIdSendMsgUnread,
      listTopicAdminSeenNotRep : state => state.totalCountUnreadMsg.listTopicAdminSeenNotRep,
    }),
    listCountUnreadMsg(){
      return this.listUserByIdSendMsgUnread.sort((a,b) => b.last_message_at - a.last_message_at).slice(0,2);
    },
    listCountAdminSeenNotrep(){
      return this.listTopicAdminSeenNotRep.sort((a,b) => b.last_message_at - a.last_message_at).slice(0,2);
    },
    checkLenghtListCountUnreadMsg(){
      return this.listUserByIdSendMsgUnread.length;
    },
    checkLenghtListTopicAdminSeenNotRep(){
      return this.listTopicAdminSeenNotRep.length;
    }
  },
  watch:{
    checkLenghtListCountUnreadMsg(){
      store.commit('totalCountUnreadMsg/SET_LIST_USER_BY_ID_UNREAD_MSG',JSON.parse(JSON.stringify(this.listUserByIdSendMsgUnread)));
    },
    checkLenghtListTopicAdminSeenNotRep(){
      store.commit('totalCountUnreadMsg/SET_LIST_INQUIRY_ADMIN_SEEN_NOT_REP',JSON.parse(JSON.stringify(this.listTopicAdminSeenNotRep)));
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.stat {
  display: flex;
  align-items: center;
}

.chart-status-up,
.chart-status-down {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  width: 30px;
  height: 30px;
}

.chart-status-up {
  background-color: rgba(55, 125, 255, 0.1);
  color: #377dff;
}

.chart-status-down {
  background-color: rgba(220, 0, 0, 0.1);
  color: #dc0000;
}

.chart-rate {
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
}

.card-3-status {
  .user-info-group {
    margin-top: 10px;
    .user-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .user-name {
        margin-left: 10px;
        font-size: 14px;
      }
      .user-id {
        display: flex;
        align-items: center;
      }
    }

    .user-info-2 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding-right: 50px;
      .user-info-detail {
        margin-left: 10px;
        width: 100%;
        cursor: pointer;
        .user-info-single {
          display: flex;
          justify-content: space-between;
        }
        .user-message {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>


